<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Send Messsage</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1" style="align-items: center">
          <b-col md="12">
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="msg_class"
            >
              <v-select
                ref="msg_class"
                multiple
                :closeOnSelect="false"
                v-model="msgClass"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillMsgSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="msgClass[0] != 0">
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="msg_section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="msgSection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="msgSecOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkMsgSections()"
                ref="msg_section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="msg_date"
            >
              <flat-pickr
                ref="msg_date"
                :config="config"
                v-model="msgObj.dt"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Select Status"
              invalid-feedback="status is required."
              ref="msg_status"
            >
              <v-select
                ref="msg_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="msgObj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveMessage()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Send Message</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility5"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility5 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1">
          <b-col
            v-if="checkInObj"
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Show Time In</h4>
            <b-form-checkbox v-model="checkInObj.valueBool" switch />
          </b-col>
          <b-col
            v-if="checkOutObj"
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Show Time Out</h4>
            <b-form-checkbox v-model="checkOutObj.valueBool" switch />
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveChecks()"
              :disabled="savingCh"
              block
            >
              <b-spinner v-if="savingCh" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Reports</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1" style="align-items: center">
          <b-col md="12">
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="rep_class"
            >
              <v-select
                ref="rep_class"
                multiple
                :closeOnSelect="false"
                v-model="repClass"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillRepSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="repClass[0] != 0">
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="rep_section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="repSection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="repSecOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkRepSections()"
                ref="rep_section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="rep_date"
            >
              <flat-pickr
                ref="rep_date"
                :config="config2"
                v-model="repDate"
                class="form-control"
                placeholder="Select Date"
                @on-change="setRepDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Select Status"
              invalid-feedback="status is required."
              ref="rep_status"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                ref="rep_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="repObj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
                @input="setStatus()"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="getReport()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Show</span>
            </b-button>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="getSheet()"
              :disabled="sheetLoading"
              block
            >
              <b-spinner v-if="sheetLoading" small type="grow" />
              <span v-else>Attendance Sheet</span>
            </b-button>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="warning"
              @click="exportReport()"
              :disabled="exporting"
              block
            >
              <b-spinner v-if="exporting" small type="grow" />
              <span v-else>Export</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Smart Attendance</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1">
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="msg_date"
            >
              <flat-pickr
                ref="msg_date"
                :config="config"
                v-model="smartObj.dt"
                class="form-control"
                placeholder="Select Date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Gr Numbers"
              invalid-feedback="Please enter gr separated by space"
              ref="grs"
            >
              <b-form-textarea
                ref="grs"
                placeholder="Enter gr numbers seperated by space"
                v-model="smartObj.gr"
                rows="4"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Status"
              invalid-feedback="Status is required."
              ref="sm_status"
            >
              <v-select
                ref="sm_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="smartObj.status"
                :options="smartOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveSmart()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <!-- Details -->
    <b-row v-if="showreport">
      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="editAtt"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Edit Attendace</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideAtt()"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="pt-1 pb-1" style="align-items: center">
            <b-col md="3">
              <b-img
                v-if="attendance.picture == null || attendance.picture == ''"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                style="width: 100px; height: 100px; object-fit: cover"
              />
              <b-img
                v-else
                :src="attendance.picture"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 50%;
                "
              />
            </b-col>
            <b-col md="8" class="ml-1">
              <h5>Name: {{ attendance.name }}</h5>
              <h5>Father Name: {{ attendance.father_name }}</h5>
              <h5>Gr# {{ attendance.grNo }}</h5>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group
                label="Time"
                invalid-feedback="time is required."
                ref="time"
              >
                <flat-pickr
                  v-model="timePicker"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h:i:K',
                  }"
                  @input="setTime()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Date"
                invalid-feedback="date is required."
                ref="date"
              >
                <flat-pickr
                  ref="date"
                  :config="config2"
                  v-model="datepickr"
                  class="form-control"
                  placeholder="Select Date."
                  @input="setTime()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group
                label="Select Status"
                invalid-feedback="status is required."
                ref="status"
              >
                <v-select
                  ref="status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  v-model="attendance.obj.status"
                  :options="statusOptions"
                  :reduce="(opt) => opt.value"
                  label="name"
                  placeholder="select status"
                />
              </b-form-group>
            </b-col>
            <b-col class="mt-1" md="12">
              <b-button
                variant="success"
                class="mr-1 fullWidth"
                @click="saveAttendance()"
                :disabled="request"
                block
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-if="request == false"> Save </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-col>
        <div class="d-flex justify-content-between">
          <b-button
            variant="outline-secondary"
            class="mr-50"
            @click="showreport = false"
          >
            <feather-icon class="" icon="ArrowLeftIcon" />
            Back
          </b-button>
          <!-- <div>
            <b-button
              class="mr-50"
              v-for="t in types"
              :key="type"
              :variant="t.value == report.type ? 'primary' : 'outline-primary'"
              @click="setType(t.value)"
            >
              {{ t.name }}
            </b-button>
          </div> -->
        </div>
        <b-card class="mt-1">
          <b-row style="align-items: center">
            <!-- <b-col md="2">
              <b-form-group
                label="Select type"
                invalid-feedback="type is required."
                ref="type"
              >
                <v-select
                  ref="type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  v-model="report.type"
                  :options="types"
                  :reduce="(opt) => opt.value"
                  label="name"
                  @input="setType()"
                  placeholder="select type"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="2" v-if="showClasses">
              <b-form-group
                label="Select Class"
                invalid-feedback="class is required."
                ref="class"
              >
                <v-select
                  ref="class"
                  multiple
                  :closeOnSelect="false"
                  v-model="classID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classesOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(val) => val.id"
                  @input="FillSections()"
                  placeholder="Select class"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" v-if="showClasses">
              <b-form-group
                label="Select Section"
                invalid-feedback="section is required."
                ref="section"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="secID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="admittedSections"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  @input="checkSections()"
                  ref="section"
                  label="section"
                  placeholder="Select section"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Date"
                invalid-feedback=" date is required."
                ref="date"
              >
                <flat-pickr
                  ref="date"
                  :config="config"
                  v-model="report.dtFrom"
                  class="form-control"
                  placeholder="Select Date."
                />
                <!-- @input="setDate()" -->
              </b-form-group>
            </b-col>
            <b-button
              class="ml-1"
              :disabled="gridLoading"
              variant="primary"
              @click="loadgrid()"
            >
              Show
            </b-button>
            <b-button
              variant="success"
              class="ml-1"
              :disabled="saving"
              @click="save()"
            >
              Save
            </b-button>
            <b-button
              class="ml-1 btn-icon"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Smart Attendance"
              @click="showSmart()"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
            <b-button
              class="ml-1 btn-icon"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Reports"
              @click="openReport()"
            >
              <feather-icon icon="BarChart2Icon" />
            </b-button>
            <b-button
              class="ml-1 btn-icon"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Messages"
              @click="openMessage()"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              class="ml-1 btn-icon"
              variant="outline-secondary"
              v-b-tooltip.hover.top
              title="Settings"
              @click="openSett()"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </b-row>
          <b-row class="mt-1" v-if="showGrid">
            <b-col lg="10" md="9">
              <b-form-group label="Search">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block"
                  placeholder="Search by name or gr"
                />
              </b-form-group>
            </b-col>
            <b-col lg="2" md="3">
              <b-form-group label="Sort By">
                <v-select
                  v-model="sortBy"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortOptions"
                  :reduce="(val) => val.value"
                  label="text"
                  @input="sortData()"
                  :clearable="false"
                  placeholder="Select"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-table
                class="mt-1"
                :busy="gridLoading"
                show-empty
                :items="filteredAtt"
                :fields="fields"
                responsive
                hover
                small
              >
                <template #table-busy>
                  <div
                    class="d-flex justify-content-center mb-3"
                    style="margin-top: 50px"
                  >
                    <b-spinner
                      style="width: 3rem; height: 3rem"
                      type="grow"
                      variant="primary"
                      label="Spinning"
                    ></b-spinner>
                  </div>
                </template>

                <template #cell(cls)="data">
                  <b-badge style="margin-inline: 2px" variant="light-primary">
                    {{ data.item.cls }}
                  </b-badge>
                </template>

                <template #cell(timein)="data">
                  <b-badge variant="light-primary" v-if="data.item.obj.timeIn">
                    {{
                      new Intl.DateTimeFormat("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                      }).format(new Date(data.item.obj.timeIn))
                    }}
                  </b-badge>
                </template>
                <template #cell(timeout)="data">
                  <b-badge variant="light-primary" v-if="data.item.obj.timeOut">
                    {{
                      new Intl.DateTimeFormat("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                      }).format(new Date(data.item.obj.timeOut))
                    }}
                  </b-badge>
                </template>

                <template #cell(status)="data">
                  <div class="d-flex align-items-center">
                    <b-button
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status == 'P'
                          ? 'success'
                          : 'outline-success'
                      "
                      @click="changeAtt(data.index, 'P')"
                    >
                      P
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status == 'A'
                          ? 'danger'
                          : 'outline-danger'
                      "
                      @click="changeAtt(data.index, 'A')"
                    >
                      A
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status == 'L' ? 'info' : 'outline-info'
                      "
                      @click="changeAtt(data.index, 'L')"
                    >
                      L
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status.toLowerCase() == 'late'
                          ? 'warning'
                          : 'outline-warning'
                      "
                      @click="changeAtt(data.index, 'Late')"
                    >
                      Late
                    </b-button>
                    <!-- <b-button
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status == 'H' ? 'info' : 'outline-info'
                      "
                      @click="changeAtt(data.index, 'H')"
                    >
                      H
                    </b-button> -->
                    <b-button
                      v-if="timeObj && timeObj.valueBool"
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status == 'ED'
                          ? 'danger'
                          : 'outline-danger'
                      "
                      @click="changeAtt(data.index, 'ED')"
                    >
                      ED
                    </b-button>
                    <b-button
                      v-if="timeObj && timeObj.valueBool"
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      class="text-nowrap"
                      :variant="
                        data.item.obj.status == 'Late + ED'
                          ? 'primary'
                          : 'outline-primary'
                      "
                      @click="changeAtt(data.index, 'Late + ED')"
                    >
                      Late + ED
                    </b-button>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                      @click="Edit(data.item)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- attendance card -->
    <b-row align-h="center" v-else-if="dataLoading">
      <b-spinner
        class="m-5"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <div v-else>
      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Settings</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideSideBar()"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="pt-1 pb-1">
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Barcode</h4>
              <b-form-checkbox
                v-model="settings.attendanceMode"
                switch
                value="barcode"
                unchecked-value=""
              />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Notification on arrival</h4>
              <b-form-checkbox v-model="settings.notifyArrival" switch />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Notification on departure</h4>
              <b-form-checkbox v-model="settings.notifyDeparture" switch />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Show dues</h4>
              <b-form-checkbox v-model="settings.showDues" switch />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Show recent attendance</h4>
              <b-form-checkbox v-model="settings.showRecent" switch />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Auto hide</h4>
              <b-form-checkbox v-model="settings.autoHide" switch />
            </b-col>
            <b-col v-if="settings.autoHide" md="12">
              <h4 class="mr-1">
                Hide After <span style="font-size: 14px">(seconds)</span>
              </h4>
              <b-form-input
                id="mc-first-name"
                type="number"
                placeholder=""
                ref="autosec"
                v-model="settings.hideAfterSeconds"
              />
            </b-col>
            <b-col class="mt-1" md="12">
              <b-button
                variant="success"
                class="mr-1 fullWidth"
                @click="saveSettings()"
                :disabled="request"
                block
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-if="request == false"> Save </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-row align-h="center">
        <b-col v-if="studentLoading" md="4">
          <b-card class="text-center p-1" style="position: relative">
            <b-spinner
              class="m-5"
              style="width: 4rem; height: 4rem"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </b-card>
        </b-col>
        <b-col lg="4" md="5" v-else>
          <b-card
            class="text-center p-1"
            style="position: relative"
            :class="{
              'set-border': checkTime
                ? currentStd.isEarly ||
                  currentStd.dues > 0 ||
                  (currentStaff && currentStaff.isEarly)
                : currentStd.dues > 0,
              'set-border-late': currentStaff
                ? currentStaff.isLate
                : currentStd.isLate,
            }"
          >
            <div
              style="position: absolute; right: 15px; top: 15px; display: none"
            >
              <!-- style="position: absolute; right: 15px; top: 15px" -->
              <feather-icon
                class="cursor-pointer"
                icon="SettingsIcon"
                size="22"
                v-b-tooltip.hover.right
                title="Settings"
                @click="showSettings()"
              />
              <br />
              <template v-if="syncObj && syncObj.valueBool">
                <b-spinner
                  v-if="syncing"
                  class="mt-2"
                  variant="primary"
                  label="Spinning"
                  style="width: 22px; height: 22px"
                ></b-spinner>
                <feather-icon
                  v-else
                  class="cursor-pointer mt-2"
                  icon="RefreshCcwIcon"
                  size="22"
                  v-b-tooltip.hover.right
                  title="Sync Attendance"
                  :badge="syncData.length"
                  @click="syncAtt()"
                />
              </template>
            </div>
            <template v-if="currentStaff">
              <b-img
                v-if="currentStaff.staff.picture"
                class="round mb-1"
                :src="currentStaff.staff.picture"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 50%;
                "
                :class="{ 'set-picSize': picObj && picObj.valueBool }"
              />
              <b-img
                v-else
                class="round mb-1"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 50%;
                "
                :class="{ 'set-picSize': picObj && picObj.valueBool }"
              />
              <h3 v-if="currentStaff.staff.name" class="mb-1">
                {{ currentStaff.staff.name }}
              </h3>
              <h4 v-if="currentStaff.designation">
                {{ currentStaff.staff.designation }}
              </h4>
              <h4 v-else>-</h4>
              <h1 v-if="currentStaff.time" class="mt-1">
                {{
                  new Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(currentStaff.time))
                }}
              </h1>
              <b-badge v-if="currentStaff.isEarly" variant="danger">
                Early Departure
              </b-badge>
              <b-badge v-else-if="currentStaff.isLate" variant="warning">
                Late
              </b-badge>
              <br />
              <b-badge
                v-if="
                  (currentStaff.isEarly || currentStaff.isLate) &&
                  currentStaff.delay
                "
                :variant="currentStaff.isEarly ? 'danger' : 'warning'"
                class="mt-25"
              >
                {{ currentStaff.delay }}
              </b-badge>
            </template>

            <template v-else>
              <b-img
                v-if="
                  currentStd.std.picture == '' || currentStd.std.picture == null
                "
                class="round mb-1"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 50%;
                "
                :class="{
                  'set-picSize': picObj && picObj.valueBool,
                  'set-border': currentStd.dues > 0,
                }"
              />
              <b-img
                v-else
                class="round mb-1"
                :src="currentStd.std.picture"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 50%;
                "
                :class="{
                  'set-picSize': picObj && picObj.valueBool,
                  'set-border': currentStd.dues > 0,
                }"
              />
              <h3 v-if="currentStd.std.name" class="mb-1">
                {{ currentStd.std.name }}
              </h3>
              <h3 v-else class="mb-1">-</h3>
              <h4 v-if="currentStd.std.cls" style="margin-bottom: 0.5rem">
                {{ currentStd.std.cls }}
              </h4>
              <h4 v-else style="margin-bottom: 0.5rem">-</h4>
              <h4 style="font-weight: 400; margin-bottom: 0.5rem">
                GR #
                <span v-if="currentStd.std.grNo">{{
                  currentStd.std.grNo
                }}</span>
                <span v-else>-</span>
              </h4>
              <h4
                v-if="settings.showDues"
                style="font-weight: 400; margin-bottom: 0.5rem"
              >
                Dues: <span v-if="currentStd.dues">{{ currentStd.dues }}</span>
                <span v-else>-</span>
              </h4>
              <h1 v-if="currentStd.time" class="mt-1">
                {{
                  new Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(currentStd.time))
                }}
              </h1>
              <b-badge v-if="currentStd.isEarly" variant="danger">
                Early Departure
              </b-badge>
              <b-badge v-else-if="currentStd.isLate" variant="warning">
                Late
              </b-badge>
              <br />
              <b-badge
                v-if="
                  (currentStd.isEarly || currentStd.isLate) && currentStd.delay
                "
                :variant="currentStd.isEarly ? 'danger' : 'warning'"
                class="mt-25"
              >
                {{ currentStd.delay }}
              </b-badge>
            </template>

            <!-- <h4 class="text-danger font-weight-bolder">dues</h4> -->
            <b-row align-h="center">
              <b-form-input
                class="mt-1"
                style="width: 80%"
                id="mc-first-name"
                placeholder="Scan here"
                ref="name"
                autofocus
                v-model="studentID"
                @keyup.enter="GetStudent()"
              />
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="1" md="2" class="d-flex flex-wrap d-md-block">
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Settings'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="SettingsIcon"
              size="22"
              @click="showSettings()"
            />
          </b-card>
          <b-card
            v-if="syncObj && syncObj.valueBool"
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Sync Attendance'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <b-spinner
              v-if="syncing"
              class=""
              variant="primary"
              label="Spinning"
              style="width: 22px; height: 22px"
            ></b-spinner>
            <feather-icon
              v-else
              class="cursor-pointer"
              icon="RefreshCcwIcon"
              size="22"
              :badge="syncData.length + staffData.length"
              @click="syncAtt()"
            />
          </b-card>
          <!-- <b-card
            v-if="syncObj && syncObj.valueBool"
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Student In'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="UserPlusIcon"
              size="22"
              :badge="stdInCount"
            />
          </b-card> -->
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Smart Attendance'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="CheckCircleIcon"
              size="22"
              @click="showSmart()"
            />
          </b-card>
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Reports'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="BarChart2Icon"
              size="22"
              @click="openReport()"
            />
          </b-card>
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Messages'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="MailIcon"
              size="22"
              @click="openMessage()"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col lg="4" md="5">
          <b-button variant="primary" block @click="openDetails()">
            Show Details
          </b-button>
        </b-col>
        <b-col lg="1" md="2"></b-col>
      </b-row>
      <b-row v-if="this.settings.showRecent && this.filteredItems.length > 0">
        <b-col md="12">
          <h3>Recent Attendace</h3>
        </b-col>
        <b-col lg="3" md="4" v-for="card in filteredItems" :key="card">
          <b-card
            :class="{
              'set-border': checkTime
                ? card.isEarly || card.dues > 0
                : card.dues > 0,
              'set-border-late': card.isLate,
            }"
          >
            <b-row v-if="card.isStaff">
              <b-col md="3" class="pr-md-0">
                <b-avatar
                  v-if="card.staff.picture == '' || card.staff.picture == null"
                  size="48"
                  src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                />
                <b-avatar v-else size="48" :src="card.staff.picture" />
              </b-col>
              <b-col md="9">
                <h5>{{ card.staff.name }}</h5>
                <h5>
                  {{ card.staff.designation ? card.staff.designation : "-" }}
                </h5>
                <h5>
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(card.time))
                  }}
                </h5>
                <template v-if="checkTime">
                  <b-badge
                    v-if="card.isEarly"
                    variant="danger"
                    class="mr-25 mb-25"
                    >Early Departure</b-badge
                  >
                  <b-badge
                    v-else-if="card.isLate"
                    variant="warning"
                    class="mr-25 mb-25"
                  >
                    Late
                  </b-badge>
                  <b-badge
                    v-if="(card.isEarly || card.isLate) && card.delay"
                    :variant="card.isEarly ? 'danger' : 'warning'"
                    class="mr-25 mb-25"
                  >
                    {{ card.delay }}
                  </b-badge>
                  <b-badge variant="success">Time {{ card.toCheck }}</b-badge>
                </template>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col md="3" class="pr-md-0">
                <b-avatar
                  v-if="card.std.picture == '' || card.std.picture == null"
                  size="48"
                  src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                  :class="{
                    'set-border': card.dues > 0,
                  }"
                />
                <b-avatar
                  v-else
                  size="48"
                  :src="card.std.picture"
                  :class="{
                    'set-border': card.dues > 0,
                  }"
                />
              </b-col>
              <b-col md="9">
                <h5>{{ card.std.name }}</h5>
                <h5>
                  {{ card.std.cls }}
                  <span class="ml-50">GR# {{ card.std.grNo }}</span>
                </h5>
                <h5>
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(card.time))
                  }}
                  <span v-if="settings.showDues" class="ml-50"
                    >Dues: {{ card.dues }}</span
                  >
                </h5>
                <template v-if="checkTime">
                  <b-badge
                    v-if="card.isEarly"
                    variant="danger"
                    class="mr-25 mb-25"
                    >Early Departure</b-badge
                  >
                  <b-badge
                    v-else-if="card.isLate"
                    variant="warning"
                    class="mr-25 mb-25"
                  >
                    Late
                  </b-badge>
                  <b-badge
                    v-if="(card.isEarly || card.isLate) && card.delay"
                    :variant="card.isEarly ? 'danger' : 'warning'"
                    class="mr-25 mb-25"
                  >
                    {{ card.delay }}
                  </b-badge>
                  <b-badge variant="success">Time {{ card.toCheck }}</b-badge>
                </template>
              </b-col>
            </b-row>
            <!-- variant="transparent" -->
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BAvatar,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BModal,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

import axios from "axios";


export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BCard,
    BRow,
    BImg,
    BAvatar,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BModal,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    // let right = {};
    // this.$store.state.menu.forEach((el) => {
    //   el.children.forEach((ch) => {
    //     right[ch.route] = {
    //       view: ch.view,
    //       add: ch.add,
    //       edit: ch.edit,
    //       delete: ch.delete,
    //     };
    //   });
    // });
    // // console.log(right);
    // this.$store.commit("setRights", right);

    // if (!this.$store.state.rights[this.$route.name]) {
    //   // console.log(this.$store.state.rights[this.$route.name]);
    //   this.$router.replace({
    //     name: "misc-not-authorized",
    //   });
    // } else {
    //   this.rights = this.$store.state.rights[this.$route.name];
    //   this.LoadClasses();
    //   this.LoadSettings();
    //   this.loadSessions();
    //   this.loadOther();
    // }
    this.LoadClasses();
    this.LoadSettings();
    this.loadSessions();
    this.loadOther();
    // this.loadOtherAll();
    if (this.syncObj && this.syncObj.valueBool) {
      this.loadAll();
      this.loadAllStaff();
      // this.$store.commit("setSyncList", []);
      // this.$store.commit("setStaffSyncList", []);
      // this.syncData = [];
      // this.staffData = [];
    } else {
      this.$store.commit("setSyncList", []);
      this.$store.commit("setStaffSyncList", []);
      this.syncData = [];
      this.staffData = [];
    }
  },
  beforeDestroy() {
    if (this.intervalId) clearInterval(this.intervalId);
  },
  computed: {
    // stdInCount() {
    //   return this.allStudents.filter((el) => el.toCheck == "out").length;
    // },
    // stdOutCount() {
    //   return this.allStudents.filter(el => el.toCheck == 'in').length
    // },
    filteredItems: function () {
      return this.items.slice(0, 12);
    },
    filteredAtt() {
      return this.gridData.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.grNo.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      rights: {},
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        // mode: "range",
      },
      repDate: "",
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      myDepartments: [],
      request: false,
      sheetLoading: false,
      exporting: false,
      attendance: {
        picture: null,
        obj: {
          status: null,
        },
      },
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Balance",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      studentLoading: false,
      visibility: false,
      visibility2: false,
      visibility3: false,
      visibility4: false,
      visibility5: false,
      logoloading: "",
      searchQuery: "",
      sortOptions: [
        { text: "Name", value: "name" },
        { text: "GR#", value: "gr" },
      ],
      sortBy: this.$store.state.attSort,
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      report: {
        type: "",
        dtFrom: new Date().toJSON().split("T")[0],
        // dtTo: null,
        clsIDs: [],
        secIDs: [],
      },
      editAtt: false,
      showreport: false,
      rangeDate: null,
      types: [
        { name: "Student", value: "student" },
        { name: "Staff", value: "staff" },
      ],
      statusOptions: [
        { name: "All", value: "" },
        { name: "Present", value: "P" },
        { name: "Absent", value: "A" },
        { name: "Leave", value: "L" },
        { name: "Late", value: "Late" },
      ],
      smartOptions: [
        { name: "Absent", value: "A" },
        { name: "Leave", value: "L" },
        { name: "Late", value: "Late" },
      ],
      showClasses: false,
      classes: [],
      classesOptions: [{ name: "All Classes", id: 0 }],
      admittedSections: [{ section: "All Sections", id: 0 }],

      classID: [0],
      secID: [0],

      gridData: [],
      showGrid: false,
      gridLoading: false,
      fields: [
        // { label: "profile", key: "profile" },
        { label: "gr no", key: "grNo" },
        { label: "name", key: "name" },
        { label: "Father name", key: "father_name" },
        { label: "class", key: "cls" },
        { label: "status", key: "status" },
        // { key: "actions", label: "actions" },
      ],

      errors: {
        status: false,
      },

      index: null,
      items: [],
      accountOptions: [
        // {
        //   id: 1,
        //   code: 999,
        // },
      ],

      secText: "Section",
      classText: "Class",
      selected: null,
      myObj: {
        picture: null,
        cls: null,
        grNo: null,
        name: null,
      },
      currentStd: {
        std: {
          picture: null,
          cls: null,
          grNo: null,
          name: null,
        },
      },
      settings: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        notifyArrival: false,
        notifyDeparture: false,
        showDues: false,
        showRecent: false,
        autoHide: false,
        hideAfterSeconds: 5,
        attendanceMode: "",
      },
      msgObj: {
        dt: "",
        clsIDs: [0],
        secIDs: [0],
        status: "",
      },
      msgSecOptions: [{ section: "All Sections", id: 0 }],
      msgClass: [0],
      msgSection: [0],

      repObj: {
        type: "",
        dtFrom: "",
        clsIDs: [0],
        secIDs: [0],
        status: [],
      },
      repSecOptions: [{ section: "All Sections", id: 0 }],
      repClass: [0],
      repSection: [0],

      detailObj: {},
      timeout: 10000,
      studentID: null,
      saving: false,

      smartObj: {
        status: "",
        dt: new Date(),
        gr: "",
      },
      sessions: [],
      sessionID: 0,
      picObj: null,
      syncObj: {
        valueBool: true,
      },
      allStudents: [],
      syncing: false,
      syncData: this.$store.state.syncList,
      intervalId: null,
      allStaff: [],
      staffData: this.$store.state.staffSyncList,
      currentStaff: null,
      // {
      //   id: 0,
      //   name: "",
      //   picture: null,
      //   designation: "",
      // },
      checkInObj: null,
      checkOutObj: null,
      savingCh: false,
      timeObj: null,
      checkTime: false,
      hideDuesAlert: null,
      unMObj: null,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openSett() {
      this.visibility5 = true;
    },
    async saveChecks() {
      // console.log(this.checkInObj, this.checkOutObj);
      this.savingCh = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Settings/SaveNew?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: [this.checkInObj, this.checkOutObj],
        message: "Settings saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.savingCh = false;
      if (status) {
        this.loadOther();
        this.visibility5 = false;
      }
    },

    sortData() {
      this.$store.commit("setAttSort", this.sortBy);

      if (this.sortBy == "gr") {
        this.gridData = this.gridData.sort((a, b) =>
          a.grNo
            .toLowerCase()
            .localeCompare(b.grNo.toLowerCase(), "en", { numeric: true })
        );
      } else {
        this.gridData = this.gridData.sort((a, b) =>
          a.name
            .toLowerCase()
            .localeCompare(b.name.toLowerCase(), "en", { numeric: true })
        );
      }
    },

    async loadOtherAll() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettingsAll?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["hideDuesAlert"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      // console.log(res);
      let obj = res.find((el) => el.key == "hideDuesAlert");
      this.hideDuesAlert = obj ? obj.valueBool : false;
    },
    async loadOther() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: [
          "picture_size",
          "check_in",
          "check_out",
          "studentTimings",
          "unmarked-attendance",
        ],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      // console.log(res);
      this.picObj = res.find((el) => el.key == "picture_size");
      this.timeObj = res.find((el) => el.key == "studentTimings");
      this.unMObj = res.find((el) => el.key == "unmarked-attendance");
      if (this.timeObj && this.timeObj.valueBool) this.checkTime = true;
      else this.checkTime = false;
      // this.syncObj = res.find((el) => el.key == "attendance_sync");
      // console.log(this.syncObj);

      let a = res.find((el) => el.key == "check_in");
      if (!a) {
        this.checkInObj = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "check_in",
          value: "check_in",
          valueBool: false,
          valueNumber: null,
        };
      } else this.checkInObj = a;

      let b = res.find((el) => el.key == "check_out");
      if (!b) {
        this.checkOutObj = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "check_out",
          value: "check_out",
          valueBool: false,
          valueNumber: null,
        };
      } else this.checkOutObj = b;
      // console.log(this.checkInObj, this.checkOutObj);
      let obj = this.fields.find((el) => el.label == "time in");
      if (this.checkInObj.id > 0 && !this.checkInObj.valueBool && obj) {
        this.fields = this.fields.filter((el) => el.label != "time in");
      }
      if (this.checkInObj.id > 0 && this.checkInObj.valueBool && !obj) {
        this.fields.splice(4, 0, { label: "time in", key: "timein" });
      }

      let obj2 = this.fields.find((el) => el.label == "time out");
      if (this.checkOutObj.id > 0 && !this.checkOutObj.valueBool && obj2) {
        this.fields = this.fields.filter((el) => el.label != "time out");
      }
      if (this.checkOutObj.id > 0 && this.checkOutObj.valueBool && !obj2) {
        this.fields.splice(this.fields.length - 1, 0, {
          label: "time out",
          key: "timeout",
        });
      }
    },
    async loadAll() {
      this.dataLoading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "Attendance/LoadAllStudents?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allStudents = await this.get(obj);
      // console.log(this.allStudents);
      this.intervalId = setInterval(this.syncAtt, 31000);
      // console.log(this.intervalId);

      this.dataLoading = false;
    },
    async loadAllStaff() {
      var obj = {
        url:
          this.$store.state.domain +
          "Attendance/LoadAllStaff?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      this.allStaff = res.dataNew ? res.dataNew : res.data;
      // console.log(this.allStaff);
    },
    async syncAtt() {
      // console.log("syncing");
      if (this.syncData.length > 0) {
        this.syncing = true;
        let temp = [...this.syncData];
        this.syncData = [];
        this.$store.commit("setSyncList", []);

        let data = temp.map((el) => ({
          id: el.std.grNo,
          time: el.time,
        }));
        // console.log(data);

        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SyncAttendance?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&userID=" +
            this.$store.state.userData.userID,
          body: data,
          message: "Attendance saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });

        if (!status) {
          this.syncData = [...this.syncData, ...temp];
          this.$store.commit("setSyncList", this.syncData);
        }

        this.syncing = false;
      }
      if (this.staffData.length > 0) {
        this.syncing = true;
        let temp = [...this.staffData];
        this.staffData = [];
        this.$store.commit("setStaffSyncList", []);

        let data = temp.map((el) => ({
          id: el.staff.id.toString(),
          time: el.time,
        }));
        // console.log(data);

        var status2 = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SyncAttendanceStaff?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&userID=" +
            this.$store.state.userData.userID,
          body: data,
          message: "Attendance saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });

        if (!status2) {
          this.staffData = [...this.staffData, ...temp];
          this.$store.commit("setStaffSyncList", this.staffData);
        }

        this.syncing = false;
      }
      //  else {
      //   this.$bvToast.toast("No record to sync", {
      //     title: "Error!",
      //     variant: "danger",
      //     toaster: "b-toaster-top-center",
      //   });
      // }
    },

    async loadSessions() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sessions = await this.get(obj);
      let res = this.sessions.find((el) => el.isCurrentSession);
      this.sessionID = res ? res.id : 0;
    },
    changeAtt(ind, val) {
      this.filteredAtt[ind].obj.status = val;
    },
    async save() {
      if (this.gridData.length != 0) {
        this.saving = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SaveWeb?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.gridData,
          message: "Attendance saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) {
          this.loadgrid();
        }
      }
    },
    openDetails() {
      this.showreport = true;
      this.report.type = "student";
      this.showClasses = true;
    },
    setType(val) {
      this.report.type = val;
      if (this.report.type == "student") this.showClasses = true;
      else this.showClasses = false;
    },

    setTime() {
      console.log(this.timePicker);
      console.log(this.datepickr);
    },

    openMessage() {
      this.msgObj = {
        dt: "",
        clsIDs: [0],
        secIDs: [0],
        status: "P",
      };
      this.visibility2 = true;

      var elem = this.$refs["msg_class"];
      elem.state = undefined;
      // var elem = this.$refs["msg_section"];
      // elem.state = undefined;
      var elem = this.$refs["msg_date"];
      elem.state = undefined;
    },

    async saveMessage() {
      // console.log(this.msgObj);
      if (
        this.msgClass.length == 0 ||
        this.msgSection.length == 0 ||
        this.msgObj.dt == ""
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.msgClass[0] !== 0) {
          this.msgObj.clsIDs = this.msgClass;
        } else {
          this.msgObj.clsIDs = [];
        }
        if (this.msgSection[0] !== 0) {
          //console.log(this.secID);
          this.msgObj.secIDs = this.msgSection;
        } else {
          this.msgObj.secIDs = [];
        }
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SendMessage?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.msgObj,
          message: "Message send successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility2 = false;
        }
      }
    },
    FillMsgSections() {
      // console.log(this.msgClass);
      if (this.msgClass[0] == 0 && this.msgClass.length !== 1) {
        this.msgClass = this.msgClass.filter((e) => e !== 0);
        // console.log(this.msgClass);
        this.classes.forEach((elem) => {
          if (this.msgClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSecOptions.push(el));
            } else {
              // console.log("null");
              this.msgSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.msgClass[this.msgClass.length - 1] !== 0 &&
        this.msgClass.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.msgClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSecOptions.push(el));
            } else {
              // console.log("null");
              this.msgSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.msgClass[this.msgClass.length - 1] == 0) {
        this.msgClass = [0];
        // console.log(this.msgClass);
        this.msgSecOptions = [{ section: "All Sections", id: 0 }];
        this.msgSection = [0];
      } else {
        this.msgSecOptions = [{ section: "All Sections", id: 0 }];
        this.msgSection = [0];
      }
      // this.CheckClass();
    },
    checkMsgSections() {
      // console.log(this.msgSection);
      if (this.msgSection[0] == 0 && this.msgSection.length !== 1) {
        this.msgSection = this.msgSection.filter((e) => e !== 0);
        // console.log(this.msgSection);
      } else if (this.msgSection[this.msgSection.length - 1] == 0) {
        this.msgSection = [0];
        // console.log(this.msgSection);
      }
    },

    FillSections() {
      //   if (this.classID.includes(0) && this.classID.length == 2) {
      //     this.classID = this.classID.filter((e) => e !== 0);
      //     console.log(this.classID);
      //   }

      // console.log(this.classID);
      if (this.classID[0] == 0 && this.classID.length !== 1) {
        this.classID = this.classID.filter((e) => e !== 0);
        // console.log(this.classID);
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.classID[this.classID.length - 1] !== 0 &&
        this.classID.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.classID[this.classID.length - 1] == 0) {
        this.classID = [0];
        // console.log(this.classID);
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      } else {
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      }
      // this.CheckClass();
    },
    checkSections() {
      // console.log(this.secID);
      if (this.secID[0] == 0 && this.secID.length !== 1) {
        this.secID = this.secID.filter((e) => e !== 0);
        // console.log(this.secID);
      } else if (this.secID[this.secID.length - 1] == 0) {
        this.secID = [0];
        // console.log(this.secID);
      }
      // this.CheckSec();
    },
    CheckSec() {
      var elem = this.$refs["section"];
      if (this.secID.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    FillRepSections() {
      // console.log(this.repClass);
      if (this.repClass[0] == 0 && this.repClass.length !== 1) {
        this.repClass = this.repClass.filter((e) => e !== 0);
        // console.log(this.repClass);
        this.classes.forEach((elem) => {
          if (this.repClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.repSecOptions.push(el));
            } else {
              // console.log("null");
              this.repSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.repClass[this.repClass.length - 1] !== 0 &&
        this.repClass.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.repClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.repSecOptions.push(el));
            } else {
              // console.log("null");
              this.repSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.repClass[this.repClass.length - 1] == 0) {
        this.repClass = [0];
        // console.log(this.repClass);
        this.repSecOptions = [{ section: "All Sections", id: 0 }];
        this.repSection = [0];
      } else {
        this.repSecOptions = [{ section: "All Sections", id: 0 }];
        this.repSection = [0];
      }
      // this.CheckClass();
    },
    checkRepSections() {
      // console.log(this.repSection);
      if (this.repSection[0] == 0 && this.repSection.length !== 1) {
        this.repSection = this.repSection.filter((e) => e !== 0);
        // console.log(this.repSection);
      } else if (this.repSection[this.repSection.length - 1] == 0) {
        this.repSection = [0];
        // console.log(this.repSection);
      }
    },
    setRepDate(sd, dateStr) {
      // console.log(sd, dateStr);
      if (dateStr) {
        if (sd.length > 1) {
          let date = dateStr.split(" to ");
          if (date.length == 1) {
            this.repObj.dtFrom = date[0];
            this.repObj.dtTo = date[0];
          } else {
            this.repObj.dtFrom = date[0];
            this.repObj.dtTo = date[1];
          }
        } else {
          this.repObj.dtFrom = dateStr;
          this.repObj.dtTo = null;
        }
      } else {
        this.repObj.dtFrom = null;
        this.repObj.dtTo = null;
      }
      // console.log(this.repObj);
    },
    setStatus() {
      if (this.repObj.status[this.repObj.status.length - 1] == "") {
        this.repObj.status = [""];
      } else {
        this.repObj.status = this.repObj.status.filter((el) => el != "");
      }
      // console.log(this.repObj.status);
    },
    openReport() {
      const fd = new Date();
      const firstDate = `${fd.getFullYear()}-${(fd.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-01`;

      const ld = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
      const lastDate = `${ld.getFullYear()}-${(ld.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ld.getDate().toString().padStart(2, "0")}`;

      // console.log(firstDate, lastDate);

      this.repObj = {
        type: "",
        dtFrom: firstDate,
        dtTo: lastDate,
        clsIDs: [0],
        secIDs: [0],
        status: [""],
      };
      this.repDate = `${firstDate} to ${lastDate}`;

      this.visibility3 = true;

      var elem = this.$refs["rep_class"];
      elem.state = undefined;
      // var elem = this.$refs["rep_section"];
      // elem.state = undefined;
      var elem = this.$refs["rep_date"];
      elem.state = undefined;
    },

    async getReport() {
      // console.log(this.repObj);
      if (
        this.repClass.length == 0 ||
        this.repSection.length == 0 ||
        this.repObj.status.length == 0 ||
        !this.repObj.dtFrom ||
        !this.repObj.dtTo
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        let obj = {
          type: "student",
          dtFrom: this.repObj.dtFrom,
          dtTo: this.repObj.dtTo,
          clsIDs: [],
          secIDs: [],
        };
        if (this.repClass[0] !== 0) {
          this.repObj.clsIDs = this.repClass;
          obj.clsIDs = this.repClass;
        } else {
          this.repObj.clsIDs = "";
        }
        if (this.repSection[0] !== 0) {
          this.repObj.secIDs = this.repSection;
          obj.secIDs = this.repSection;
        } else {
          this.repObj.secIDs = "";
        }
        let status = "";
        if (this.repObj.status[0] !== "") {
          status = this.repObj.status;
        }
        this.request = true;

        if (this.unMObj && this.unMObj.valueBool) {
          var st = await this.post({
            url:
              this.$store.state.domain +
              "Attendance/SaveUnmarkedRange?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: obj,
            importing: true,
            context: this,
            token: this.$store.state.userData.token,
          });
        }

        var url =
          `https://${this.reportDomain}.myskool.app/Attendance/Show?type=student&dtFrom=` +
          this.repObj.dtFrom +
          "&dtTo=" +
          this.repObj.dtTo +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&cls=" +
          this.repObj.clsIDs +
          "&sec=" +
          this.repObj.secIDs +
          "&status=" +
          status;

        window.open(url, "_blank");
        this.request = false;
        // if (status) {
        //   this.visibility3 = false;
        // }
      }
    },
    async exportReport() {
      // console.log(this.repObj);
      if (
        this.repClass.length == 0 ||
        this.repSection.length == 0 ||
        this.repObj.status.length == 0 ||
        !this.repObj.dtFrom ||
        !this.repObj.dtTo
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        let obj = {
          type: "student",
          dtFrom: this.repObj.dtFrom,
          dtTo: this.repObj.dtTo,
          clsIDs: [],
          secIDs: [],
        };
        if (this.repClass[0] !== 0) {
          this.repObj.clsIDs = this.repClass;
          obj.clsIDs = this.repClass;
        } else {
          this.repObj.clsIDs = "";
        }
        if (this.repSection[0] !== 0) {
          this.repObj.secIDs = this.repSection;
          obj.secIDs = this.repSection;
        } else {
          this.repObj.secIDs = "";
        }
        let status = "";
        if (this.repObj.status[0] !== "") {
          status = this.repObj.status;
        }
        this.exporting = true;

        if (this.unMObj && this.unMObj.valueBool) {
          var st = await this.post({
            url:
              this.$store.state.domain +
              "Attendance/SaveUnmarkedRange?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: obj,
            importing: true,
            context: this,
            token: this.$store.state.userData.token,
          });
        }

        var url =
          `https://${this.reportDomain}.myskool.app/Attendance/ShowExport?type=student&dtFrom=` +
          this.repObj.dtFrom +
          "&dtTo=" +
          this.repObj.dtTo +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&cls=" +
          this.repObj.clsIDs +
          "&sec=" +
          this.repObj.secIDs +
          "&status=" +
          status;

        axios
          .get(url)
          .then((response) => {
            if (response.data.status == "success") {
              window.open(response.data.data, "_blank");
            } else {
              this.$bvToast.toast("Something went wrong", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          })
          .finally(() => {
            this.exporting = false;
          });
      }
    },
    async getSheet() {
      if (this.repClass.length == 0 || this.repSection.length == 0) {
        return this.$bvToast.toast("Please select class and section", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.repClass[0] !== 0) {
          this.repObj.clsIDs = this.repClass;
        } else {
          this.repObj.clsIDs = "";
        }
        if (this.repSection[0] !== 0) {
          this.repObj.secIDs = this.repSection;
        } else {
          this.repObj.secIDs = "";
        }

        this.sheetLoading = true;
        if (this.$store.state.currentBranch.organizationType == "coaching") {
          var url =
            `https://${this.reportDomain}.myskool.app/List/Show?type=attendance&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&cls=" +
            this.repObj.clsIDs +
            "&sec=" +
            this.repObj.secIDs +
            "&session=" +
            this.sessionID +
            "&status=&mob=false";

          window.open(url, "_blank");
        } else {
          var url =
            `https://${this.reportDomain}.myskool.app/List/Show?type=attendance&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&cls=" +
            this.repObj.clsIDs +
            "&sec=" +
            this.repObj.secIDs +
            "&status=&mob=false";

          window.open(url, "_blank");
        }
        this.sheetLoading = false;
      }
    },
    async loadgrid() {
      this.gridLoading = true;
      this.showGrid = true;
      if (this.classID[0] !== 0) {
        this.report.clsIDs = this.classID;
      } else {
        this.report.clsIDs = [];
      }
      if (this.secID[0] !== 0) {
        //console.log(this.secID);
        this.report.secIDs = this.secID;
      } else {
        this.report.secIDs = [];
      }
      // console.log(this.report);
      this.request = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Attendance/LoadAttendanceGrid?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: this.report,
        attendance: true,
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.gridData = status;
        this.sortData();
      }
      this.gridLoading = false;
    },
    async Edit(item) {
      this.attendance = item;
      // console.log(this.attendance);
      this.editAtt = true;
      this.datepickr = this.attendance.date.split("T")[0];
      this.timePicker = this.attendance.date.split("T")[1];

      var elem = this.$refs["account"];
      elem.state = undefined;
      var elem = this.$refs["balance"];
      elem.state = undefined;
    },
    async GetStudent() {
      if (this.studentID.length !== 0) {
        // sync setting
        if (this.syncObj && this.syncObj.valueBool) {
          this.studentLoading = true;
          if (
            this.studentID.toLowerCase().startsWith("e") ||
            this.studentID.endsWith("+")
          ) {
            let extractedNumber = this.studentID.replace(/[Ee+]/g, "");

            let stf = this.allStaff.find(
              (el) => el.staff.id.toString() == extractedNumber
            );
            // console.log(stf);
            if (stf) {
              this.currentStaff = { ...stf };
              let date = new Date();
              let day = new Date().toJSON().split("T")[0];
              let time = date.toTimeString().split(" ")[0];
              // console.log(time);
              this.currentStaff.time = day + "T" + time;
              this.currentStaff.isStaff = true; // for recent
              this.items.unshift(this.currentStaff);
              this.staffData.push(this.currentStaff);
              this.$store.commit("setStaffSyncList", this.staffData);

              if (this.checkTime && this.currentStaff.timings) {
                // check out
                if (this.currentStaff.toCheck == "out") {
                  let outTime = new Date(this.currentStaff.timings.timeOut)
                    .toTimeString()
                    .split(" ")[0];
                  // console.log("to: ", outTime);
                  if (time < outTime) {
                    this.currentStaff.isEarly = true;

                    let t1 = outTime.split(":").slice(0, 2).join(":");
                    let t2 = time.split(":").slice(0, 2).join(":");
                    // console.log("staff out", t1, t2);

                    const date1 = new Date(`2023-01-05T${t1}:00Z`);
                    const date2 = new Date(`2023-01-05T${t2}:00Z`);
                    const diffMs = date1 - date2;

                    // Convert milliseconds to hours, minutes, and seconds
                    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
                    const diffMins = Math.floor(
                      (diffMs % (1000 * 60 * 60)) / (1000 * 60)
                    );
                    //const diffSecs = Math.floor((diffMs % (1000 * 60)) / 1000);

                    // console.log(`early diff: ${diffHrs} hrs, ${diffMins} mins`);
                    if (diffHrs > 0) {
                      this.currentStaff.delay = `${diffHrs} ${
                        diffHrs == 1 ? "hr" : "hrs"
                      } ${diffMins} ${diffMins > 1 ? "mins" : "min"} Early`;
                    } else {
                      this.currentStaff.delay = `${diffMins} ${
                        diffMins > 1 ? "mins" : "min"
                      } Early`;
                    }
                    // console.log("early");
                  } else {
                    this.currentStaff.isEarly = false;
                    // console.log("out time");
                  }
                } else {
                  // check in
                  let index = this.allStaff.indexOf(stf);
                  this.allStaff[index].toCheck = "out";

                  let inTime = new Date(this.currentStaff.timings.timeIn)
                    .toTimeString()
                    .split(" ")[0];
                  // console.log("in: ", inTime);
                  if (time > inTime) {
                    this.currentStaff.isLate = true;

                    let t1 = time.split(":").slice(0, 2).join(":");
                    let t2 = inTime.split(":").slice(0, 2).join(":");
                    // console.log("staff in", t1, t2);

                    const date1 = new Date(`2023-01-05T${t1}:00Z`);
                    const date2 = new Date(`2023-01-05T${t2}:00Z`);
                    const diffMs = date1 - date2;

                    // Convert milliseconds to hours, minutes, and seconds
                    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
                    const diffMins = Math.floor(
                      (diffMs % (1000 * 60 * 60)) / (1000 * 60)
                    );
                    //const diffSecs = Math.floor((diffMs % (1000 * 60)) / 1000);

                    // console.log(`late diff: ${diffHrs} hrs, ${diffMins} mins`);
                    if (diffHrs > 0) {
                      this.currentStaff.delay = `${diffHrs} ${
                        diffHrs == 1 ? "hr" : "hrs"
                      } ${diffMins} ${diffMins > 1 ? "mins" : "min"} Late`;
                    } else {
                      this.currentStaff.delay = `${diffMins} ${
                        diffMins > 1 ? "mins" : "min"
                      } Late`;
                    }
                  } else {
                    this.currentStaff.isLate = false;
                    // console.log("in time");
                  }
                }
              }
            } else {
              this.currentStaff = null;
              this.$bvToast.toast("No staff found.", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
            this.currentStd = {
              std: {
                picture: null,
                cls: null,
                grNo: null,
                name: null,
              },
              dues: null,
            };
          } else {
            let extractedNumber = this.studentID
              .match(/\d+/)[0]
              .replace(/^0+/, "");
            // console.log(extractedNumber);
            let res = this.allStudents.find(
              (el) =>
                el.std.grNo.match(/\d+/)[0].replace(/^0+/, "") ==
                extractedNumber
            );
            //console.log("CURRENT STD: ", res);
            //console.log(res);
            if (res) {
              this.currentStd = { ...res };
              let date = new Date();
              let day = new Date().toJSON().split("T")[0];
              let time = date.toTimeString().split(" ")[0];
              this.currentStd.time = day + "T" + time;
              // console.log(this.currentStd.time);
              this.items.unshift(this.currentStd);
              this.syncData.push(this.currentStd);
              this.$store.commit("setSyncList", this.syncData);

              if (this.checkTime && this.currentStd.timings) {
                //Checkout
                if (this.currentStd.toCheck == "out") {
                  let outTime = new Date(this.currentStd.timings.timeOut)
                    .toTimeString()
                    .split(" ")[0];
                  // console.log("out: ", outTime);

                  if (time < outTime) {
                    this.currentStd.isEarly = true;

                    let t1 = outTime.split(":").slice(0, 2).join(":");
                    let t2 = time.split(":").slice(0, 2).join(":");
                    // console.log("out", t1, t2);

                    const date1 = new Date(`2023-01-05T${t1}:00Z`);
                    const date2 = new Date(`2023-01-05T${t2}:00Z`);
                    const diffMs = date1 - date2;

                    // Convert milliseconds to hours, minutes, and seconds
                    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
                    const diffMins = Math.floor(
                      (diffMs % (1000 * 60 * 60)) / (1000 * 60)
                    );
                    //const diffSecs = Math.floor((diffMs % (1000 * 60)) / 1000);

                    // console.log(`early diff: ${diffHrs} hrs, ${diffMins} mins`);
                    if (diffHrs > 0) {
                      this.currentStd.delay = `${diffHrs} ${
                        diffHrs == 1 ? "hr" : "hrs"
                      } ${diffMins} ${diffMins > 1 ? "mins" : "min"} Early`;
                    } else {
                      this.currentStd.delay = `${diffMins} ${
                        diffMins > 1 ? "mins" : "min"
                      } Early`;
                    }
                  } else {
                    this.currentStd.isEarly = false;
                    // console.log("out time");
                  }
                } else {
                  //Check In
                  let index = this.allStudents.indexOf(res);
                  this.allStudents[index].toCheck = "out";

                  let inTime = new Date(this.currentStd.timings.timeIn)
                    .toTimeString()
                    .split(" ")[0];
                  // console.log("in: ", inTime);

                  if (time > inTime) {
                    this.currentStd.isLate = true;

                    let t1 = time.split(":").slice(0, 2).join(":");
                    let t2 = inTime.split(":").slice(0, 2).join(":");
                    // console.log("in", t1, t2);

                    const date1 = new Date(`2023-01-05T${t1}:00Z`);
                    const date2 = new Date(`2023-01-05T${t2}:00Z`);
                    const diffMs = date1 - date2;

                    // Convert milliseconds to hours, minutes, and seconds
                    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
                    const diffMins = Math.floor(
                      (diffMs % (1000 * 60 * 60)) / (1000 * 60)
                    );
                    //const diffSecs = Math.floor((diffMs % (1000 * 60)) / 1000);

                    // console.log(`late diff: ${diffHrs} hrs, ${diffMins} mins`);
                    if (diffHrs > 0) {
                      this.currentStd.delay = `${diffHrs} ${
                        diffHrs == 1 ? "hr" : "hrs"
                      } ${diffMins} ${diffMins > 1 ? "mins" : "min"} Late`;
                    } else {
                      this.currentStd.delay = `${diffMins} ${
                        diffMins > 1 ? "mins" : "min"
                      } Late`;
                    }
                  } else {
                    this.currentStd.isLate = false;
                    // console.log("in time");
                  }
                }
              }
            } else {
              this.currentStd = {
                std: {
                  picture: null,
                  cls: null,
                  grNo: null,
                  name: null,
                },
                dues: null,
              };
              this.$bvToast.toast("No student found.", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
            this.currentStaff = null;
          }
          this.studentLoading = false;

          this.studentID = "";
          if (this.settings.autoHide) {
            setTimeout(() => {
              this.currentStd = {
                std: {
                  picture: null,
                  cls: null,
                  grNo: null,
                  name: null,
                },
                dues: null,
              };
              this.studentID = "";
            }, this.settings.hideAfterSeconds * 1000);
          }
        }
        // general
        else {
          this.studentLoading = true;
          var obj = {
            url:
              this.$store.state.domain +
              "Attendance/LoadStudentData?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&gr=" +
              this.studentID +
              "&userID=" +
              this.$store.state.userData.userID,
            token: this.$store.state.userData.token,
          };
          this.currentStd = await this.get(obj);
          // console.log("res:", this.currentStd);
          this.studentLoading = false;

          if (this.currentStd == "No data found.") {
            this.currentStd = {
              std: {
                picture: null,
                cls: null,
                grNo: null,
                name: null,
              },
              dues: null,
            };
            return this.$bvToast.toast("No student found.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
          } else if (this.currentStd == "Student's profile is not active.") {
            this.currentStd = {
              std: {
                picture: null,
                cls: null,
                grNo: null,
                name: null,
              },
              dues: null,
            };
            return this.$bvToast.toast("Student's profile is not active.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
          } else {
            // this.myObj = this.currentStd.std;
            // console.log("obj:", this.myObj);

            this.items.unshift(this.currentStd);
            // console.log(this.items);
          }
          this.studentID = "";
          if (this.settings.autoHide) {
            setTimeout(() => {
              this.currentStd = {
                std: {
                  picture: null,
                  cls: null,
                  grNo: null,
                  name: null,
                },
                dues: null,
              };
              this.studentID = "";
            }, this.settings.hideAfterSeconds * 1000);
          }
        }
      }
    },
    CheckAccount() {
      var elem = this.$refs["account"];
      if (this.myObj.account == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckBalance() {
      var elem = this.$refs["balance"];
      var re = /^[0-9]+$/;
      if (re.test(this.myObj.balance) && this.myObj.balance !== 0) {
        this.myObj.balance = parseInt(this.myObj.balance);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    hideAtt() {
      this.editAtt = false;
    },

    showSmart() {
      this.visibility4 = true;
    },
    async saveSmart() {
      if (
        this.smartObj.gr.trim() == "" ||
        this.smartObj.date == "" ||
        this.smartObj.status == ""
      ) {
        this.$bvToast.toast("Please enter the details properly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.smartObj.gr = this.smartObj.gr.replace(/\n|,/g, " ");
        // console.log(this.smartObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SmartAttendance?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.smartObj,
          message: "Attendance saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility4 = false;
        }
      }
    },

    showSettings() {
      this.visibility = true;
    },
    async LoadSettings() {
      var obj = {
        url:
          this.$store.state.domain +
          "AttendanceSettings/LoadData?campusID=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);

      if (result == null) {
        this.settings = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          notifyArrival: false,
          notifyDeparture: false,
          showDues: false,
          showRecent: false,
          autoHide: false,
          hideAfterSeconds: 5,
          attendanceMode: "",
        };
      } else {
        this.settings = result;
      }
      // console.log("set", this.settings);
    },
    async LoadClasses() {
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      // console.log("class", this.classes);
      this.classes.forEach((el) => {
        this.classesOptions.push(el.cls);
      });
    },
    async LoadSections() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sections?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sections = await this.get(obj);
      // console.log("sections", this.sections);
    },
    async saveSettings() {
      this.request = true;
      let x = parseInt(this.settings.hideAfterSeconds);
      this.settings.hideAfterSeconds = !x ? 5 : x;

      var status = await this.post({
        url:
          this.$store.state.domain +
          "AttendanceSettings/Save?db=" +
          this.$store.state.userData.db,
        body: this.settings,
        message: "Settings saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.visibility = false;
        this.LoadSettings();
      }
    },
    async saveAttendance() {
      this.request = true;
      // this.attendance.obj.date = this.datepickr + this.timePicker;
      this.attendance.obj.date = new Date().toJSON();
      var status = await this.put({
        url:
          this.$store.state.domain +
          "Attendance/id=" +
          this.attendance.obj.id +
          "?db=" +
          this.$store.state.userData.db,
        body: this.attendance.obj,
        message: "Attendance updated successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.editAtt = false;
        this.loadgrid();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card-body {
  padding: 0.85rem;
}
@keyframes myfade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.set-picSize {
  width: 150px !important;
  height: 150px !important;
}

.set-border {
  border: 4px solid var(--danger) !important;
}
.set-border-late {
  border: 4px solid var(--warning) !important;
}
</style>
